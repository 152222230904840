.mainTitle {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.mainheadingContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.mainTitleContainer {
  text-align: center;
}

.inputContainerr {
  margin-top: 20px;
}

.datePickerField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  text-align: start;
  height: 100%;
}

.ant-picker {
  width: 100%;
  border: none !important;
  font-size: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  padding: 10px !important;
}

.ant-picker-focused {
  border: none !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  border-color: rgb(216, 25, 255) !important;
  border-right: none !important;
  box-shadow: none !important;
}

.ant-picker-input>input {
  font-size: 20px !important;
  color: #fff !important;
}

.ant-picker-input>input::placeholder {
  color: #757575 !important;
}

.ant-picker-suffix {
  background-color: transparent !important;
  color: #fff !important;
}

.ant-picker-clear {
  background-color: rgb(3, 14, 50) !important;
  color: #fff !important;
}



.datePicker {
  color: white;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  padding: 10px;
  background-color: transparent;
  text-align: start;
  font-size: 20px;
  width: 100%
}



.datePickerContainer {
  position: "relative";
  min-height: 30px;
}

.hiddenfield {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 30px;
  min-height: 30px;
}

.hiddenfieldInput {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
  height: 50px;
}

.datePickerContent {
  z-index: 2;
  color: "white";
  background-color: #030e32;
  height: 100%;
  width: 160px;
}

.datePickerTopField {
  display: flex;
  justify-content: space-between;
  height: 30px;
}