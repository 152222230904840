@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto','Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: rgb(216, 25, 255) !important;
    height: 4px;
}

.ant-steps-icon-dot::after {
    color: rgb(68, 61, 239) !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    /* font-size: 20px; */
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
    font-size: 17px;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: white !important;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
    color: white !important;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
    background: white !important;
}

.stepperContainer {
    color: white;
    width: 65%;
    padding-top: 40px;
    margin: auto !important;
}

.stepContainer {
    margin-bottom: 40px;
}

@media screen and (max-width: 800px) {
    .stepperContainer {
        width: 90% !important;
    }
}

@media screen and (max-width: 700px) {
    .stepperContainer {
        width: 90% !important;
    }
    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
        color: white !important;
        font-size: 15px;
    }
}

@media screen and (max-width: 500px) {
    .stepperContainer {
        width: 100% !important;
    }
}

@media screen and (max-width: 576px) {
    .stepContainer {
        display: none !important;
      
    }.stepperContainer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

.wrapper {
    color: white;
    width: 60%;
    padding: 30px;
    margin: auto;
}
.button-cont-text {
  padding-top: 60px;
}

.optionList{
    color:grey ;
    background-color:lightgray ;
    border:none
}
.mainContainer {
    padding: 10px;
    margin-top: 15px;
    color: white;
    font-size: 20px;
    background-color: #06205b;
  }
  .inputContainer {
    display: flex;
    align-items: center;
  }
  .input {
    margin: 10px;
    text-align: center;
  }
  .labelText {
    font-size: 16px;
    text-align: start;
  }
  
  .newcontainer input{
    display: none;
  }
  .newcontainer{
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .newcontainer .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: #ffeded38;
    transition: all 0.2s ease;
  }
  
  .newcontainer input:checked ~ .checkmark {
    background-color:rgb(216, 25, 255);
    opacity: 0.9;
    transition: all 0.2s ease;
  }
  
  .newcontainer .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .newcontainer input:checked ~ .checkmark:after {
    display: block;
    
  }
  
  .newcontainer .checkmark:after {
    left: 0.4em;
    top: 0.15em;
    width: 0.25em;
    height: 0.5em;
    border: solid rgb(255, 255, 255);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
  }
  
.title {
  text-align: center;
  color: white;
  font-weight: 100;
}
.body {
  height: 400%;
}
.buttonTitle {
  color: white !important;
}
.radioButtonContainer{
  margin-top: 20px;
}
.radioButtonTitle{
  text-align: center;
}
@media screen and (max-width: 600px) {
  .buttonTitle {
    font-size: 18px !important;
  }
}

.inputField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
}

input:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: white;
  -webkit-transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

::-webkit-calendar-picker-indicator {
  background-color: rgb(216, 25, 255);
  border-radius: 5px;
  font-size: 16px;
  position: relative;
}

.nonUsTitle {
  text-align: center;
  margin-top: 15px;
  color: white;
}

.nonUsHeading {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-weight: 150px;
  font-size: 20px;
}

.entitySplashText {
  color: #f3f3f3;
  font-weight: 400;
  margin: 40px;
  font-size: large;
}

.listItems {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}

.bullet {
  background-color: rgb(216, 25, 255);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 7px 0;
  margin-right: 5px;
}

.bulletText {
  padding: 0;
  margin: 0;
  text-align: left;
  color: #f3f3f3;
  font-size: large;
}

.getStarted {
  font-size: large;
  margin-top: 30px;
}

@media screen and (max-width: 600px) {
  .nonUsHeading {
    font-size: 16px !important;
  }
}
.privacyContainer {
  padding: 12px 8px;
  display: flex;
  background-color: #0e2061;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  margin: 15px 0px;
}

.conatinerText {
  margin: 0;
  padding: 0;
  font-size: medium;
}

.privacyContainer:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal-content {
  background-color: rgb(3, 14, 50) !important;
  color: #f3f3f3;
}

.privacyText {
  color: rgb(216, 25, 255);
}

.close {
  background-color: white;
  color: rgb(3, 14, 50);
  border: none;
  font-size: x-large;
  font-weight: 900;
}

.mainTitle {
  color: white;
  font-weight: bold;
  font-size: 20px;
}

.mainheadingContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.mainTitleContainer {
  text-align: center;
}

.inputContainerr {
  margin-top: 20px;
}

.datePickerField {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  color: white;
  text-align: start;
  height: 100%;
}

.ant-picker {
  width: 100%;
  border: none !important;
  font-size: 20px !important;
  color: #fff !important;
  background-color: transparent !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  padding: 10px !important;
}

.ant-picker-focused {
  border: none !important;
  border-bottom: 2px solid rgb(216, 25, 255) !important;
  border-color: rgb(216, 25, 255) !important;
  border-right: none !important;
  box-shadow: none !important;
}

.ant-picker-input>input {
  font-size: 20px !important;
  color: #fff !important;
}

.ant-picker-input>input::placeholder {
  color: #757575 !important;
}

.ant-picker-suffix {
  background-color: transparent !important;
  color: #fff !important;
}

.ant-picker-clear {
  background-color: rgb(3, 14, 50) !important;
  color: #fff !important;
}



.datePicker {
  color: white;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  padding: 10px;
  background-color: transparent;
  text-align: start;
  font-size: 20px;
  width: 100%
}



.datePickerContainer {
  position: "relative";
  min-height: 30px;
}

.hiddenfield {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 30px;
  min-height: 30px;
}

.hiddenfieldInput {
  width: 100%;
  font-size: 20px;
  padding: 10px;
  outline: none;
  border: none;
  border-bottom: 2px solid rgb(216, 25, 255);
  background-color: transparent;
  color: white;
  text-align: start;
  height: 50px;
}

.datePickerContent {
  z-index: 2;
  color: "white";
  background-color: #030e32;
  height: 100%;
  width: 160px;
}

.datePickerTopField {
  display: flex;
  justify-content: space-between;
  height: 30px;
}
.successScreenContainer {
  color: white;
  text-align: center;
  margin: 20px;
}
.succesTitle {
  font-size: 54px;
  margin: 20px;
  font-weight: 100 !important;
  color: #d818ff;
}

.logoContainer {
  margin: 20px 20px 40px 20px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  margin: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: none;
  border-top: 2px solid #142e6e;
  padding: 10px;
}

.Icon {
  width: 30px;
  height: 30px;
}

.titleMain {
  font-size: 20px;
  font-weight: 200;
  margin: 10px;
  padding: 10px;
}

.topContainer {
  color: white;
  text-align: center;
  /* margin: 20px */
}

.announcementTitle {
  color: #aa17d2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.announcementTitleContainer {
  margin-top: 50px;
}

.titleText {
  color: white;
  text-align: left;
}

.heading {
  color: white;
  margin: 10px;
}

.documentTitleContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.imageName {
  color: rgb(216, 25, 255);
}

.captureButton {
  margin: 10px;
  border: none;
  background-color: transparent;
}

.captureLogo {
  width: 60px;
}

.backsideButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.backsideButtonDisable {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.documentContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}

.documentImage {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.button-cont-text {
  padding-top: 30px;
}

.headerWithBack {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }

  .inputFile {
    font-size: 18px !important;
  }

  .imageName {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 16px;
  }

  .inputFile {
    font-size: 15px !important;
    text-align: center;
  }
}
.dropzone {
  margin-top: 10px;
}
.dropzoneContainer {
  width: 100%;
  border: 2px dashed #79a8e6;
  height: 100%;
  cursor: pointer;
}
.dropzoneContainerContent {
  color: white;
  font-weight: 400;
  margin-top: 14px;
}
.span {
  color: #559df9;
  margin-left: 3px;
  font-weight: bold;
}
.heading {
  padding: 10px;
}
@media screen and (max-width: 450px) {
  .dropzoneContainerContent {
    font-size: small;
  }
}

.logoContainer {
  margin: 20px 20px 40px 20px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  margin: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: none;
  border-top: 2px solid #142e6e;
  padding: 10px;
}

.Icon {
  width: 30px;
  height: 30px;
}

.titleMain {
  font-size: 20px;
  font-weight: 200;
  margin: 10px;
  padding: 10px;
}

.topContainer {
  color: white;
  text-align: center;
  /* margin: 20px */
}

.announcementTitle {
  color: #aa17d2;
  text-align: start;
}

.announcementTitleContainer {
  margin-top: 50px;
}

.titleText {
  color: white;
}

.heading {
  color: white;
  margin: 10px;
}

.documentTitleContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.imageName {
  color: rgb(216, 25, 255);
}

.captureButton {
  margin: 10px;
  border: none;
  background-color: transparent;
}

.captureLogo {
  width: 60px;
}

.backsideButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.backsideButtonDisable {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.documentContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}

.documentImage {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.button-cont-text {
  padding-top: 30px;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }

  .inputFile {
    font-size: 18px !important;
  }

  .imageName {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 16px;
  }

  .inputFile {
    font-size: 15px !important;
    text-align: center;
  }
}
.container1 {
  width: 100%;
  margin: auto;
}

.titleMainContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.termLink {
  color: rgb(216, 25, 255);
  font-weight: 400;
}

.termLink:hover {
  color: rgb(144, 21, 168) !important;
}

.TermsButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.deactiveButton {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.headerContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.termsContainer {
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
  line-height: 22px;
}

.inputsMainContainer {
  margin-top: 20px;
}

.successLogo {
  color: white;
  text-align: center;
  margin: 20px;

}
.docPreview{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.docPreview img {
  width: 100%;
}
.inputContainer {
  padding: 10px 0px;
}

.titleContainerSuccess {
  color: white;
  text-align: center;
  margin: 20px;
}

.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 10px;
  width: 50px !important;
}

.continueBtn {
  padding-top: 20px;
}

.optionList {
  color: white;
  background-color: #33334d;
  border: none;
  opacity: 1 !important;
}

.agreeTermsContainer {
  margin: 15px 0px
}

.application-container{
  background-color :rgb(3, 14, 50);
  padding: 10px 20px 20px 20px;
}

.loadingContainer {
  text-align: center;
  width: 100%;
}
.loadingTitle {
  font-size: 44px;
  font-weight: 400px;
  color: white;
}
.loadingImage {
  width: 400px;
  height: 400px;
  margin-top: 80px;
}
@media screen and (max-width: 500px) {
  .loadingImage {
    width: 300px !important;
  }
}

.header {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
  text-align: center;
}

.Maincontainer {
  width: 40% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.containerRow {
  background-color: rgb(3, 14, 50);
  padding: 30px;
}

.login-H2 {
  color: white;
  padding: 10px;
}

.button {
  padding: 30px;
  margin: 10px;
  width: 100%;
}

.buttonText {
  width: 150px;
  height: 40px;
  background-color: rgb(6, 34, 97);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid transparent;
}

.activeButtonText {
  width: 150px;
  height: 40px;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  background-color: rgb(68, 61, 239);
  border: 1px solid rgb(216, 25, 255);
  margin: 10px;
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.continueBtn {
  padding-top: 20px;
}

.button-cont-text {
  padding-top: 60px;
}

.footer {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.footerSpan {
  color: rgb(216, 25, 255);
  font-weight: bold;
  margin-left: 4px;
}

.Icon {
  margin-right: 7px;
}

.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}

.mainHeader {
  text-align: center;
}
.header ul li{
  text-align: start;
  margin-bottom: 10px;
}
.verif_text{
  color: rgb(216, 25, 255);
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .login-H2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .login-H2 {
    font-size: 20px;
  }

  .buttonText {
    width: 130px;
    height: 35px;
  }

  .activeButtonText {
    width: 130px;
    height: 35px;
  }
}

@media screen and (max-width: 400px) {
  .login-H2 {
    font-size: 17px;
  }
}
.container {
  padding: 0px;
  width: 50% !important;
  margin-top: 70px;
}
.container1 {
  padding: 0px;
  width: 40% !important;
  margin-top: 70px !important ;
}
.verificationHeader{
  text-align: center 
}
.mainTitle {
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 23px;
}
.button {
  padding: 20px;
  margin: 10px;
}
.buttonContinue {
  width: 100%;
  height: 45px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.disableButton {
  width: 100%;
  height: 45px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
i {
  margin-right: 5px;
}
.button-cont-text {
  padding-top: 10px;
}
.mainHeader {
  text-align: center;
}
.title {
  color: white;
  text-align: center;
  font-size: 26px !important;
  font-weight: 300 !important;
}
.titleNumber {
  text-align: start;
  color: lightgray;
}
@media screen and (max-width: 1800px) {
  .container {
    width: 65% !important;
  }
}
@media screen and (max-width: 1600px) {
  .container {
    width: 70% !important;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 70% !important;
  }
  .title {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 80% !important;
  }
  .title {
    font-size: 24px !important;
  }
  .container1 {
    width: 45% !important;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    width: 90% !important;
  }
  .buttonContinue {
    height: 45px;
    padding: 5px;
  }
  .disableButton {
    height: 45px;
    padding: 5px;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 55% !important;
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 90% !important;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 65% !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .container1 {
    width: 75% !important;
  }
}
@media screen and (max-width: 400px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 85% !important;
  }
}
@media screen and (max-width: 300px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 95% !important;
  }
}
@media screen and (max-width: 576px) {
  .container {
    margin-top: 40px;
  }
}

/* Override the background of the dropdown */
.react-tel-input .flag-dropdown {
  background-color: rgb(37, 53, 111) !important; /* Set background color */
  border: none !important; /* Remove any border */
}

/* Change the color of the country code in the dropdown */
.react-tel-input .flag-dropdown .selected-flag {
  color: white !important; /* Set the country code color to white */
}

/* Ensure the dropdown icon (arrow) is white */
.react-tel-input .flag-dropdown .selected-flag .arrow {
  border-top: 4px solid white !important; /* Set the arrow icon color to white */
}

/* Change the background color of the country list */
.react-tel-input .country-list {
  background-color: rgb(37, 53, 111) !important; /* Set the desired background color */
}

/* Adjust the font and color inside the dropdown */
.react-tel-input .country-list .country {
  color: white !important; /* Set the text color */
}

/* Change the color on hover to a darker version of rgb(50, 70, 140) */
.react-tel-input .country-list .country:hover {
  background-color: rgb(50, 70, 140) !important; /* Darker background on hover */
}

/* Highlighted country background */
.react-tel-input .country-list .country.highlight {
  background-color: rgb(50, 70, 140) !important; /* Highlighted country background */
}

/* Make the country dial code white in the dropdown list */
.react-tel-input .country-list .country .dial-code {
  color: white !important; /* Set the dial code to white */
}

/* On hover, set country code and icon to a darker version */
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown .selected-flag:hover {
  background-color: rgb(50, 70, 140) !important; /* Darker version of the background */
  color: white !important; /* Keep the country code and icon white on hover */
}

.react-tel-input .flag-dropdown .selected-flag .arrow:hover {
  border-top: 4px solid white !important; /* Keep the arrow white on hover */
}

.react-tel-input .flag-dropdown.open .selected-flag.open {
  background-color: rgb(37, 53, 111) !important;
}
.react-tel-input .flag-dropdown .selected-flag {
  background-color: rgb(37, 53, 111) !important;
}
.titleCode {
  color: white;
  text-align: center;
  font-weight: bold;
}
.activeButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.editemail {
  width: 100%;
  height: 50px;
  background-color: rgb(33, 37, 41);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 20px;
  width: 50px !important;
}
.mainHeader {
  text-align: center;
}
.otpMessageContainer{
  margin-top: 20px
}
.otpContainer {
  display: flex;
  justify-content: space-around;
}
.otpQuestionText {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold !important;
}
.otpQuestionTextenable {
  color: rgb(216, 25, 255);
  border: none;
  outline: none;
  font-weight: bold;
  background-color: transparent;
}
.errorText {
  color: #ff4d4d;
}
.help_btn{
  /* position: absolute;
  top: 0;
  right: 0;
  padding: 15px; */
  cursor: pointer;
  font-weight: 600;
  display: flex;
  justify-content: end;
  margin-top: -15px;
  margin-right: -5px;
}
/* .modal-dialog {
  position:fixed;
  top:auto;
  right:auto;
  left:auto;
  bottom:0;
}   */
@media screen and (max-width: 800px) {
  .activeButton {
    height: 45px !important;
  }
  .deactive-button {
    height: 45px !important;
  }
  .titleCode {
    font-size: 15px !important;
  }
  .inputFields {
    width: 40px !important;
    margin: 10px;
    font-size: 24px;
  }
}
@media screen and (max-width: 500px) {
  .titleCode {
    font-size: 13px !important;
  }
}

.custom-tooltip {
  position: absolute;
  top: 20px; /* Adjust to your desired position */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
  opacity: 0.9;
  text-align: center;
}

.Input {
  height: 100%;
  width: 100%;
  font-size: 24px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 10px;
  background-color: rgb(37, 53, 111);
  color: white;
}
.inputHeader {
  margin: 10px;
}
@media screen and (max-width: 600px) {
  .Input {
    font-size: 16px !important;
  }
}

.img_container {
  background-color: rgb(23 27 52);
  padding: 10px;
  border-radius: 10px;
}

.img_container input[type="text"] {
  background: transparent;
  width: 50%;
  border-color: rgb(243, 240, 240);
  border-radius: 10px;
  margin-left: 20px;
}
.btn_textbox {
  display: flex;
  justify-content: space-between;
}
.upload_btn {
  background-color: rgb(16, 58, 225);
  padding: 5px 20px;
  border-radius: 10px;
  border: none;
}
.fileSection {
  background-color: rgb(246, 255, 253);
  color: black;
  padding: 20px;
  margin-top: 10px;
  text-align: start;
  border-radius: 10px;
}

.fileSection img:first-child {
  padding-right: 10px;
  float: left;
}
.fileSection .remove {
  display: flex;
  float: right;
}
.fileSection .remove img {
  cursor: pointer;
}
.docs_list {
  text-align: start;
  font-size: x-large;
  padding: 20px;
}

.App {
  text-align: center;
}

body {
  background: linear-gradient(rgb(6, 34, 97), rgb(3, 14, 50)) fixed;
}

body::-webkit-scrollbar{
  width: 0px;
  border-radius: 30px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 30px;
}
*::-webkit-scrollbar:hover {
  width: 0px;
  -webkit-transition: width 0.5s ease-in;
  transition: width 0.5s ease-in;
}
*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.navigation {
    background: #06184D;
    height: 100%;
    position: relative;
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.navigation::-webkit-scrollbar {
    background-color: black;
    width: 7px;
    border-radius: 30px;

}

.navigation::-webkit-scrollbar-thumb {
    background-color: pink;
    border-radius: 30px;
}

*::-webkit-scrollbar:hover {
    width: 12px;
    -webkit-transition: width 0.5s ease-in;
    transition: width 0.5s ease-in;

}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

.bigNav ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
    padding-left: 0rem;
}
.smallNav ul{
    padding-left: 0rem;
}
.smallNav ul li a{
    padding: 5px;
}
.smallNav li{
    padding: 0px;
    margin: 0px;
}
.smallNav .myIcon{
    padding: 0px;
    margin: 0px 3px;
}
li a {
    position: relative;
    color: white;
    text-decoration: none;
    font-size: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    transition: 0.5s ease-in;
}
.bigNav .title{
    max-width: -webkit-max-content;
    max-width: max-content;
    padding-right: 10px;
    font-weight: 600;
}

b:nth-child(1) {
    display: none;
}

a:hover {
    color: black;
}

.active {
    color: black;
    background-color: #D819FF;
    margin: 0px 5px;
    border-radius: 5px;
    transition: border-radius 0.5s ease-in;
}

b:nth-child(1) {
    display: block;
    position: absolute;
    height: 10px;
    width: 50%;
    right: 0;
    background-color: white;
    top: -10px;
}

b:nth-child(1)::before {
    content: '';
    position: absolute;
    right: 10px;
    width: 100%;
    height: 100%;
    background-color: #702c8b;
    transition: 0.5s ease-in;
    top: 0;
    border-bottom-right-radius: 50px;
}

b:nth-child(2) {
    display: block;
    position: absolute;
    height: 10px;
    width: 50%;
    right: 0;
    background-color: white;
    bottom: -10px;

}

b:nth-child(2)::before {
    content: '';
    position: absolute;
    right: 10px;
    width: 100%;
    height: 100%;
    background-color: #702c8b;
    transition: 0.5s ease-in;
    bottom: 0;
    border-top-right-radius: 50px;
}


.icon {
    font-size: 1.5em;
    padding-right: 10px;
}


.smallNav {
    width: 50px;

    .list .title {
        display: none;
    }

}

.bigNav {
    width: 250px;

    .list .sidebar_title {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        font-size: 20px;
        color: white;
    }
}

.dashboard_big{
    padding-top: 70px;
    width: 91%;
    background: linear-gradient(#062261, #030e32);
}
.dashboard_small{
    padding-top: 70px;
    width: 97%;
    background: linear-gradient(#062261, #030e32);
}
.tab_head_bg{
    background: rgba(118, 118, 129, 0.24);
    font-size: 20px;
    border-radius: 20px;
}
.tab_head_bg .active{
    background-color: #D819FF;
    border-radius: 20px;
    margin: 0px;
    color:white;
}

.tab_head_bg .tb_last span{
    width: 3px;
    margin-top: 2px;
    height: 70%;
    background-color: #8E8E93;
    position: absolute;
    right: -5px;
}
/* .tab_head_bg span{
    display: none;
} */
.tab_head_bg .tb_first span{
    width: 3px;
    margin-top: 5px;
    height: 70%;
    background-color: #8E8E93;
    position: absolute;
    left: -5px;
}
.tab_head_bg .tb_first .tb_last{
  display: none;
}
.tab_content{
    background-color: #06184d;
    border-radius: 20px;
    margin-top: 10px;
    height: calc(100vh - 195px);
    padding: 10px 15px;
    color: #f5f5f5;
    position: relative;
    font-size: 18px;
}
.tab_content .tb_bttn{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
}
.tb_bttn button{
    background-color: #443DEF;
    border-radius: 20px;
    padding: 5px 20px;
    border: none;
    font-size: 18px;
}
.my_popup{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
.my_popup_view{
    background-color: #06184D;
    color:#f5f5f5;
    padding: 50px 100px;
    border-radius: 10px;
    position: relative;
}
.my_popup_view_close{
    /* padding: 5px; */
    /* background-color: white; */
    /* border-radius: 50px; */
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: white;
    cursor: pointer;
}
.my_popup_view_back{
    /* padding: 5px; */
    /* background-color: white; */
    /* border-radius: 50px; */
    font-size: 20px;
    position: absolute;
    top: 5px;
    left: 10px;
    color: white;
    cursor: pointer;
}
.my_popup_done_btn{
    width: 30vw;
}
.my_popup_view h4{
    color:#f5f5f5;
    padding-bottom: 30px;
}
.my_popup_view button{
    padding: 5px 20px;
    font-size: 20px;
    border-radius: 5px;
    border: none;
}
.my_popup_view .my_popup_yes{
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
    width: 120px;
}
.my_popup_view .my_popup_no{
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
    width: 120px;
}
.My_popup_view_input{
    background-color: transparent;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 5px 10px;
    width: 20rem;
}
.my_popup_view .popup_verify{
    border-radius: 50px;
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
}
.my_popup_view .popup_thanks{
    margin-bottom: 30px;
}
.my_popup_view .popup_ok{
    border-radius: 50px;
    background-color: #06184D !important;
    border: 1px solid grey;
}
.my_popup_view .popup_Done{
    width: 100%;
    background-color: #443DEF;
    margin-top: 20px;
}
.my_popup_view_subscribe{
    width: 35rem;
}
.my_popup_view_subscribe p{
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
}
.profile_top{
    background-color: #030e32;
    height: 150px;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    text-align: right;
    color: white;
}
.profile_body{
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: -125px;
}
.profile_body .profile_img{
    position: relative;
    margin: 10px;
    border: 1px solid #443DEF;
    width: 170px;
    height: 170px;
    border-radius: 100px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile_img .catch_profile{
    background-color: black;
    color: white;
    position: absolute;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
}
.profile_img .my_img{
    width: 90%;
    height: 90%;
    background-color: black;
    border-radius: 100px;
    margin: 10px;
    overflow: hidden;
}
.profile_body .w-33{
    width: 32%;
    padding-bottom: 30px;
}

.profile_image{
    background-size: cover;
    width: 100%;
    height: 100%;
}
.headbg{
    background-color: #06215E;
    position: absolute;
    width: 100vw;
    height: 60px;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .myIcon{
        color: white;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin: 0px 5px;
    }
    .topSection{
        height: 60px;
        width: -webkit-max-content;
        width: max-content;
        background-color: #06215E;
    }
    .smallLogo{
        padding-right: 10px;
        /* border-right: 1px solid white; */
        height: 45px;
    }
    .wdth-20{
        width: 20%;
    }
    .userName{
        color:white;
        text-align: right;
    }
    .userDesig{
        color: white;
        text-align: right;
    }
    

.profileIcon{
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}
.btn_cursor{
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-height .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 100%;
    height: 300px !important ;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .modal-height .modal-dialog{
    justify-content: center;
  }
  .modal-dialog{
  display: flex;
  align-items: center;
    justify-content: center;
  }
  .modal-height .modal-dialog{
    justify-content: center;
  }

  .modal .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    height: 550px;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .certificate-modal-contain{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    pointer-events: auto;
    background-color: rgb(3, 14, 50);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  
  .certificate-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .certificate-frame {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .model-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-size: 1.2rem;
  }
  
