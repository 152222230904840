.headbg{
    background-color: #06215E;
    position: absolute;
    width: 100vw;
    height: 60px;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .myIcon{
        color: white;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin: 0px 5px;
    }
    .topSection{
        height: 60px;
        width: max-content;
        background-color: #06215E;
    }
    .smallLogo{
        padding-right: 10px;
        /* border-right: 1px solid white; */
        height: 45px;
    }
    .wdth-20{
        width: 20%;
    }
    .userName{
        color:white;
        text-align: right;
    }
    .userDesig{
        color: white;
        text-align: right;
    }
    

.profileIcon{
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}
.btn_cursor{
    cursor: pointer;
}
