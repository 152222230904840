.navigation {
    background: #06184D;
    height: 100%;
    position: relative;
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.navigation::-webkit-scrollbar {
    background-color: black;
    width: 7px;
    border-radius: 30px;

}

.navigation::-webkit-scrollbar-thumb {
    background-color: pink;
    border-radius: 30px;
}

*::-webkit-scrollbar:hover {
    width: 12px;
    transition: width 0.5s ease-in;

}

*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 30px;
}

.bigNav ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
    padding-left: 0rem;
}
.smallNav ul{
    padding-left: 0rem;
}
.smallNav ul li a{
    padding: 5px;
}
.smallNav li{
    padding: 0px;
    margin: 0px;
}
.smallNav .myIcon{
    padding: 0px;
    margin: 0px 3px;
}
li a {
    position: relative;
    color: white;
    text-decoration: none;
    font-size: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    transition: 0.5s ease-in;
}
.bigNav .title{
    max-width: max-content;
    padding-right: 10px;
    font-weight: 600;
}

b:nth-child(1) {
    display: none;
}

a:hover {
    color: black;
}

.active {
    color: black;
    background-color: #D819FF;
    margin: 0px 5px;
    border-radius: 5px;
    transition: border-radius 0.5s ease-in;
}

b:nth-child(1) {
    display: block;
    position: absolute;
    height: 10px;
    width: 50%;
    right: 0;
    background-color: white;
    top: -10px;
}

b:nth-child(1)::before {
    content: '';
    position: absolute;
    right: 10px;
    width: 100%;
    height: 100%;
    background-color: #702c8b;
    transition: 0.5s ease-in;
    top: 0;
    border-bottom-right-radius: 50px;
}

b:nth-child(2) {
    display: block;
    position: absolute;
    height: 10px;
    width: 50%;
    right: 0;
    background-color: white;
    bottom: -10px;

}

b:nth-child(2)::before {
    content: '';
    position: absolute;
    right: 10px;
    width: 100%;
    height: 100%;
    background-color: #702c8b;
    transition: 0.5s ease-in;
    bottom: 0;
    border-top-right-radius: 50px;
}


.icon {
    font-size: 1.5em;
    padding-right: 10px;
}


.smallNav {
    width: 50px;

    .list .title {
        display: none;
    }

}

.bigNav {
    width: 250px;

    .list .sidebar_title {
        display: inline-block;
        padding: 0px;
        margin: 0px;
        font-size: 20px;
        color: white;
    }
}

.dashboard_big{
    padding-top: 70px;
    width: 91%;
    background: linear-gradient(#062261, #030e32);
}
.dashboard_small{
    padding-top: 70px;
    width: 97%;
    background: linear-gradient(#062261, #030e32);
}
.tab_head_bg{
    background: rgba(118, 118, 129, 0.24);
    font-size: 20px;
    border-radius: 20px;
}
.tab_head_bg .active{
    background-color: #D819FF;
    border-radius: 20px;
    margin: 0px;
    color:white;
}

.tab_head_bg .tb_last span{
    width: 3px;
    margin-top: 2px;
    height: 70%;
    background-color: #8E8E93;
    position: absolute;
    right: -5px;
}
/* .tab_head_bg span{
    display: none;
} */
.tab_head_bg .tb_first span{
    width: 3px;
    margin-top: 5px;
    height: 70%;
    background-color: #8E8E93;
    position: absolute;
    left: -5px;
}
.tab_head_bg .tb_first .tb_last{
  display: none;
}
.tab_content{
    background-color: #06184d;
    border-radius: 20px;
    margin-top: 10px;
    height: calc(100vh - 195px);
    padding: 10px 15px;
    color: #f5f5f5;
    position: relative;
    font-size: 18px;
}
.tab_content .tb_bttn{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20px;
}
.tb_bttn button{
    background-color: #443DEF;
    border-radius: 20px;
    padding: 5px 20px;
    border: none;
    font-size: 18px;
}
.my_popup{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}
.my_popup_view{
    background-color: #06184D;
    color:#f5f5f5;
    padding: 50px 100px;
    border-radius: 10px;
    position: relative;
}
.my_popup_view_close{
    /* padding: 5px; */
    /* background-color: white; */
    /* border-radius: 50px; */
    font-size: 20px;
    position: absolute;
    top: 5px;
    right: 10px;
    color: white;
    cursor: pointer;
}
.my_popup_view_back{
    /* padding: 5px; */
    /* background-color: white; */
    /* border-radius: 50px; */
    font-size: 20px;
    position: absolute;
    top: 5px;
    left: 10px;
    color: white;
    cursor: pointer;
}
.my_popup_done_btn{
    width: 30vw;
}
.my_popup_view h4{
    color:#f5f5f5;
    padding-bottom: 30px;
}
.my_popup_view button{
    padding: 5px 20px;
    font-size: 20px;
    border-radius: 5px;
    border: none;
}
.my_popup_view .my_popup_yes{
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
    width: 120px;
}
.my_popup_view .my_popup_no{
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
    width: 120px;
}
.My_popup_view_input{
    background-color: transparent;
    border: 1px solid grey;
    border-radius: 5px;
    margin-bottom: 30px;
    padding: 5px 10px;
    width: 20rem;
}
.my_popup_view .popup_verify{
    border-radius: 50px;
    background-color: transparent;
    color: white;
    border: 2px solid #443DEF;
}
.my_popup_view .popup_thanks{
    margin-bottom: 30px;
}
.my_popup_view .popup_ok{
    border-radius: 50px;
    background-color: #06184D !important;
    border: 1px solid grey;
}
.my_popup_view .popup_Done{
    width: 100%;
    background-color: #443DEF;
    margin-top: 20px;
}
.my_popup_view_subscribe{
    width: 35rem;
}
.my_popup_view_subscribe p{
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
}
.profile_top{
    background-color: #030e32;
    height: 150px;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    text-align: right;
    color: white;
}
.profile_body{
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-top: -125px;
}
.profile_body .profile_img{
    position: relative;
    margin: 10px;
    border: 1px solid #443DEF;
    width: 170px;
    height: 170px;
    border-radius: 100px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile_img .catch_profile{
    background-color: black;
    color: white;
    position: absolute;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
}
.profile_img .my_img{
    width: 90%;
    height: 90%;
    background-color: black;
    border-radius: 100px;
    margin: 10px;
    overflow: hidden;
}
.profile_body .w-33{
    width: 32%;
    padding-bottom: 30px;
}

.profile_image{
    background-size: cover;
    width: 100%;
    height: 100%;
}