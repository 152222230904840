.logoContainer {
  margin: 20px 20px 40px 20px;
  text-align: center;
}

.title {
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  margin: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  border: none;
  border-top: 2px solid #142e6e;
  padding: 10px;
}

.Icon {
  width: 30px;
  height: 30px;
}

.titleMain {
  font-size: 20px;
  font-weight: 200;
  margin: 10px;
  padding: 10px;
}

.topContainer {
  color: white;
  text-align: center;
  /* margin: 20px */
}

.announcementTitle {
  color: #aa17d2;
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.announcementTitleContainer {
  margin-top: 50px;
}

.titleText {
  color: white;
  text-align: left;
}

.heading {
  color: white;
  margin: 10px;
}

.documentTitleContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.imageName {
  color: rgb(216, 25, 255);
}

.captureButton {
  margin: 10px;
  border: none;
  background-color: transparent;
}

.captureLogo {
  width: 60px;
}

.backsideButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.backsideButtonDisable {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 30px;
}

.documentContainer {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
}

.documentImage {
  width: 100%;
  height: 200px;
  margin-top: 15px;
}

.button-cont-text {
  padding-top: 30px;
}

.headerWithBack {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 20px;
  }

  .inputFile {
    font-size: 18px !important;
  }

  .imageName {
    font-size: 14px;
  }
}

@media screen and (max-width: 400px) {
  .heading {
    font-size: 16px;
  }

  .inputFile {
    font-size: 15px !important;
    text-align: center;
  }
}