.mainContainer {
    padding: 10px;
    margin-top: 15px;
    color: white;
    font-size: 20px;
    background-color: #06205b;
  }
  .inputContainer {
    display: flex;
    align-items: center;
  }
  .input {
    margin: 10px;
    text-align: center;
  }
  .labelText {
    font-size: 16px;
    text-align: start;
  }
  
  .newcontainer input{
    display: none;
  }
  .newcontainer{
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .newcontainer .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: #ffeded38;
    transition: all 0.2s ease;
  }
  
  .newcontainer input:checked ~ .checkmark {
    background-color:rgb(216, 25, 255);
    opacity: 0.9;
    transition: all 0.2s ease;
  }
  
  .newcontainer .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .newcontainer input:checked ~ .checkmark:after {
    display: block;
    
  }
  
  .newcontainer .checkmark:after {
    left: 0.4em;
    top: 0.15em;
    width: 0.25em;
    height: 0.5em;
    border: solid rgb(255, 255, 255);
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
  }
  