.App {
  text-align: center;
}

body {
  background: linear-gradient(rgb(6, 34, 97), rgb(3, 14, 50)) fixed;
}

body::-webkit-scrollbar{
  width: 0px;
  border-radius: 30px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 30px;
}
*::-webkit-scrollbar:hover {
  width: 0px;
  transition: width 0.5s ease-in;
}
*:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}