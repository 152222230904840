.privacyContainer {
  padding: 12px 8px;
  display: flex;
  background-color: #0e2061;
  justify-content: space-around;
  align-items: center;
  border-radius: 3px;
  margin: 15px 0px;
}

.conatinerText {
  margin: 0;
  padding: 0;
  font-size: medium;
}

.privacyContainer:hover {
  opacity: 0.6;
  cursor: pointer;
}

.modal-content {
  background-color: rgb(3, 14, 50) !important;
  color: #f3f3f3;
}

.privacyText {
  color: rgb(216, 25, 255);
}

.close {
  background-color: white;
  color: rgb(3, 14, 50);
  border: none;
  font-size: x-large;
  font-weight: 900;
}
