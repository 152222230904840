.container1 {
  width: 100%;
  margin: auto;
}

.titleMainContainer {
  color: white;
  text-align: center;
  margin: 20px;
}

.termLink {
  color: rgb(216, 25, 255);
  font-weight: 400;
}

.termLink:hover {
  color: rgb(144, 21, 168) !important;
}

.TermsButton {
  width: 100%;
  height: 50px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.deactiveButton {
  width: 100%;
  height: 50px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  font-weight: bold;
}

.headerContainer {
  color: white;
  text-align: center;
  margin: 10px;
}

.termsContainer {
  margin-top: 15px;
  color: white;
  font-size: 20px;
  background-color: #06205b;
  line-height: 22px;
}

.inputsMainContainer {
  margin-top: 20px;
}

.successLogo {
  color: white;
  text-align: center;
  margin: 20px;

}
.docPreview{
  width: fit-content;
}
.docPreview img {
  width: 100%;
}
.inputContainer {
  padding: 10px 0px;
}

.titleContainerSuccess {
  color: white;
  text-align: center;
  margin: 20px;
}

.inputFields {
  font-size: 30px;
  background-color: rgb(37, 53, 111);
  border: none;
  outline: none;
  text-align: center;
  color: white !important;
  border-radius: 5px 5px 0px 0px;
  margin: 10px;
  width: 50px !important;
}

.continueBtn {
  padding-top: 20px;
}

.optionList {
  color: white;
  background-color: #33334d;
  border: none;
  opacity: 1 !important;
}

.agreeTermsContainer {
  margin: 15px 0px
}

.application-container{
  background-color :rgb(3, 14, 50);
  padding: 10px 20px 20px 20px;
}
