.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-height .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 100%;
    height: 300px !important ;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .modal-height .modal-dialog{
    justify-content: center;
  }
  .modal-dialog{
  display: flex;
  align-items: center;
    justify-content: center;
  }
  .modal-height .modal-dialog{
    justify-content: center;
  }

  .modal .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    max-width: 800px;
    height: 550px;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .certificate-modal-contain{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    pointer-events: auto;
    background-color: rgb(3, 14, 50);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
}
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  
  .certificate-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .certificate-frame {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
  
  .model-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-size: 1.2rem;
  }
  