.header {
  padding: 30px;
  height: 100%;
  background-color: rgb(3, 14, 50);
  text-align: center;
}

.Maincontainer {
  width: 40% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.containerRow {
  background-color: rgb(3, 14, 50);
  padding: 30px;
}

.login-H2 {
  color: white;
  padding: 10px;
}

.button {
  padding: 30px;
  margin: 10px;
  width: 100%;
}

.buttonText {
  width: 150px;
  height: 40px;
  background-color: rgb(6, 34, 97);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  margin: 10px;
  border: 1px solid transparent;
}

.activeButtonText {
  width: 150px;
  height: 40px;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  background-color: rgb(68, 61, 239);
  border: 1px solid rgb(216, 25, 255);
  margin: 10px;
}

.btnContainer {
  display: flex;
  justify-content: center;
}

.continueBtn {
  padding-top: 20px;
}

.button-cont-text {
  padding-top: 60px;
}

.footer {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.footerSpan {
  color: rgb(216, 25, 255);
  font-weight: bold;
  margin-left: 4px;
}

.Icon {
  margin-right: 7px;
}

.deactive-button {
  background-color: #051d56;
  width: 100%;
  height: 45px;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}

.mainHeader {
  text-align: center;
}
.header ul li{
  text-align: start;
  margin-bottom: 10px;
}
.verif_text{
  color: rgb(216, 25, 255);
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .login-H2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 900px) {
  .login-H2 {
    font-size: 20px;
  }

  .buttonText {
    width: 130px;
    height: 35px;
  }

  .activeButtonText {
    width: 130px;
    height: 35px;
  }
}

@media screen and (max-width: 400px) {
  .login-H2 {
    font-size: 17px;
  }
}