.img_container {
  background-color: rgb(23 27 52);
  padding: 10px;
  border-radius: 10px;
}

.img_container input[type="text"] {
  background: transparent;
  width: 50%;
  border-color: rgb(243, 240, 240);
  border-radius: 10px;
  margin-left: 20px;
}
.btn_textbox {
  display: flex;
  justify-content: space-between;
}
.upload_btn {
  background-color: rgb(16, 58, 225);
  padding: 5px 20px;
  border-radius: 10px;
  border: none;
}
.fileSection {
  background-color: rgb(246, 255, 253);
  color: black;
  padding: 20px;
  margin-top: 10px;
  text-align: start;
  border-radius: 10px;
}

.fileSection img:first-child {
  padding-right: 10px;
  float: left;
}
.fileSection .remove {
  display: flex;
  float: right;
}
.fileSection .remove img {
  cursor: pointer;
}
.docs_list {
  text-align: start;
  font-size: x-large;
  padding: 20px;
}
