.container {
  padding: 0px;
  width: 50% !important;
  margin-top: 70px;
}
.container1 {
  padding: 0px;
  width: 40% !important;
  margin-top: 70px !important ;
}
.verificationHeader{
  text-align: center 
}
.mainTitle {
  color: white;
  padding: 10px;
  font-weight: bold;
  font-size: 23px;
}
.button {
  padding: 20px;
  margin: 10px;
}
.buttonContinue {
  width: 100%;
  height: 45px;
  background-color: rgb(68, 61, 239);
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
.disableButton {
  width: 100%;
  height: 45px;
  background-color: #051d56;
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 5px;
}
i {
  margin-right: 5px;
}
.button-cont-text {
  padding-top: 10px;
}
.mainHeader {
  text-align: center;
}
.title {
  color: white;
  text-align: center;
  font-size: 26px !important;
  font-weight: 300 !important;
}
.titleNumber {
  text-align: start;
  color: lightgray;
}
@media screen and (max-width: 1800px) {
  .container {
    width: 65% !important;
  }
}
@media screen and (max-width: 1600px) {
  .container {
    width: 70% !important;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 70% !important;
  }
  .title {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1200px) {
  .container {
    width: 80% !important;
  }
  .title {
    font-size: 24px !important;
  }
  .container1 {
    width: 45% !important;
  }
}
@media screen and (max-width: 1000px) {
  .container {
    width: 90% !important;
  }
  .buttonContinue {
    height: 45px;
    padding: 5px;
  }
  .disableButton {
    height: 45px;
    padding: 5px;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 55% !important;
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 90% !important;
  }
  .title {
    font-size: 22px !important;
  }
  .container1 {
    width: 65% !important;
  }
}
@media screen and (max-width: 600px) {
  .container {
    width: 100% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .container1 {
    width: 75% !important;
  }
}
@media screen and (max-width: 400px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 85% !important;
  }
}
@media screen and (max-width: 300px) {
  .container {
    width: 100% !important;
  }
  .container1 {
    width: 95% !important;
  }
}
@media screen and (max-width: 576px) {
  .container {
    margin-top: 40px;
  }
}

/* Override the background of the dropdown */
.react-tel-input .flag-dropdown {
  background-color: rgb(37, 53, 111) !important; /* Set background color */
  border: none !important; /* Remove any border */
}

/* Change the color of the country code in the dropdown */
.react-tel-input .flag-dropdown .selected-flag {
  color: white !important; /* Set the country code color to white */
}

/* Ensure the dropdown icon (arrow) is white */
.react-tel-input .flag-dropdown .selected-flag .arrow {
  border-top: 4px solid white !important; /* Set the arrow icon color to white */
}

/* Change the background color of the country list */
.react-tel-input .country-list {
  background-color: rgb(37, 53, 111) !important; /* Set the desired background color */
}

/* Adjust the font and color inside the dropdown */
.react-tel-input .country-list .country {
  color: white !important; /* Set the text color */
}

/* Change the color on hover to a darker version of rgb(50, 70, 140) */
.react-tel-input .country-list .country:hover {
  background-color: rgb(50, 70, 140) !important; /* Darker background on hover */
}

/* Highlighted country background */
.react-tel-input .country-list .country.highlight {
  background-color: rgb(50, 70, 140) !important; /* Highlighted country background */
}

/* Make the country dial code white in the dropdown list */
.react-tel-input .country-list .country .dial-code {
  color: white !important; /* Set the dial code to white */
}

/* On hover, set country code and icon to a darker version */
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown .selected-flag:hover {
  background-color: rgb(50, 70, 140) !important; /* Darker version of the background */
  color: white !important; /* Keep the country code and icon white on hover */
}

.react-tel-input .flag-dropdown .selected-flag .arrow:hover {
  border-top: 4px solid white !important; /* Keep the arrow white on hover */
}

.react-tel-input .flag-dropdown.open .selected-flag.open {
  background-color: rgb(37, 53, 111) !important;
}
.react-tel-input .flag-dropdown .selected-flag {
  background-color: rgb(37, 53, 111) !important;
}